import "./home.component.scss";
import YouTube from "react-youtube";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dialog, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import whyLogo1 from "../../assets/images/why_auto_1.png";
import whyLogo2 from "../../assets/images/why_auto_2.png";
import whyLogo3 from "../../assets/images/why_auto_3.png";
import whyLogo4 from "../../assets/images/why_auto_4.png";
import whyLogo5 from "../../assets/images/why_auto_5.png";
import whyLogo6 from "../../assets/images/why_auto_6.png";

import whyLogo1_2 from "../../assets/images/aftermarket2.svg";
import whyLogo2_2 from "../../assets/images/no_infra2.svg";
import whyLogo3_2 from "../../assets/images/automate_all_operations.png";
import whyLogo4_2 from "../../assets/images/hive_mind.png";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";

import ProgressSliderComponent from "../../components/progress-slider/progress-slider.component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function HomeComponent() {
  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
    },
  };

  const operationsSliderSettings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    fade: true,
  };

  useEffect(() => {}, []);

  const navigate = useNavigate();

  // const [dialogFLOpen, setDialogFLOpen] = useState(false);
  // const [dialogTROpen, setDialogTROpen] = useState(false);
  const [dialogWatch, setDialogWatch] = useState(false);

  const goToLink = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="home-component">
      <section className="section-main">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="wave-container">
              <div
                style={{ position: "relative", padding: "32px 0px 16px 0px" }}
              >
                <h1 className="text-center heading-font">
                  Universal <span className="text-primary ">Autonomy</span>
                </h1>
                {/* <div className="row slider">
                  <div className="col-12 col-md-6 text-center text-md-end">
                    <h1
                      className="text-center heading-font"
                      style={{ fontSize: "42px" }}
                    >
                      One Kit Any
                      <span className="d-none d-md-inline ">&nbsp;</span>
                    </h1>
                  </div>
                  <div className="col-12 col-md-6 text-center text-md-start">
                    <h1 className="heading-font" style={{ fontSize: "42px" }}>
                      <Slider {...operationsSliderSettings}>
                        <span className="text-accent">Equipment</span>
                        <span className="text-accent">Environment</span>
                        <span className="text-accent">Application</span>
                      </Slider>
                    </h1>
                  </div>
                </div> */}

                <h2 className="text-center hero-subheading-font">
                  automating mobility operations across industries
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 text-center">
            <Button
              className="button-learn-more"
              size="large"
              variant="contained"
              onClick={() => {}}
            >
              Learn More
            </Button>
          </div>
        </div> */}
      </section>

      <section className="section-video">
        <video autoPlay loop muted playsInline>
          <source
            src={require("../../assets/videos/retrofit.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </section>

      {/* <section className="section-deploy" id="section-deploy">
        <div className="container">
          <div className="row align-items-center">
            <h1 className="section-title">Deploy our robots</h1>
            <div className="col-12 col-lg-6">
              <div>
                <img
                  className="product-image"
                  src={require("../../assets/images/forklift.png")}
                />
              </div>
              <h1 className="section-title">In Your Warehouse</h1>

              <Button
                size="large"
                style={{ marginTop: "16px" }}
                variant="contained"
                onClick={() => {
                  setDialogFLOpen(true);
                }}
              >
                Watch Video
              </Button>
              <Dialog
                onClose={() => {
                  setDialogFLOpen(false);
                }}
                open={dialogFLOpen}
              >
                <DialogTitle style={{ padding: "8px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setDialogFLOpen(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      background: "#FFFFFF !important",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 8px 8px 8px" }}>
                  <YouTube
                    videoId="HTBLKqDoAM0"
                    opts={{
                      width: "100%",
                      height: "100%",
                      playerVars: {
                        autoplay: 1,
                      },
                    }}
                    onReady={(event) => {
                      event.target.playVideo();
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mobile-spacer"></div>
              <div>
                <img
                  className="product-image"
                  src={require("../../assets/images/tractor.png")}
                />
              </div>
              <h1 className="section-title">In Your Farm</h1>

              <Button
                size="large"
                style={{ marginTop: "16px" }}
                variant="contained"
                onClick={() => {
                  setDialogTROpen(true);
                }}
              >
                Watch Video
              </Button>
              <Dialog
                onClose={() => {
                  setDialogTROpen(false);
                }}
                open={dialogTROpen}
              >
                <DialogTitle style={{ padding: "8px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setDialogTROpen(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      background: "#FFFFFF !important",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 8px 8px 8px" }}>
                  <YouTube
                    videoId="g62P82dQJlw"
                    opts={{
                      width: "100%",
                      height: "100%",
                      playerVars: {
                        autoplay: 1,
                      },
                    }}
                    onReady={(event) => {
                      event.target.playVideo();
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </section> */}

      <section className="section-why-automate" id="why-automate">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">Why Automate?</h1>
              {/* <h2 className="section-subtitle">
                Use the Drivn platform to make your existing infrastructure and
                assets work harder <br className="d-none d-lg-block" />
                and more efficiently, while also optimizing costs, and
                prioritizing safety.
              </h2> */}
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img
                          src={whyLogo1}
                          style={{ width: "28px", height: "auto" }}
                        />
                      </span>
                      <span className="section-subtitle">
                        Higher Productivity
                      </span>
                    </h2>
                    <p>
                      Operate longer hours while performing more movements each
                      day with your existing fleet. Flux needs no breaks, and no
                      time off.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo2} />
                      </span>
                      <span className="section-subtitle">
                        Lower Operating Costs
                      </span>
                    </h2>
                    <p>
                      Flux enabled vehicles and equipment can operate completely
                      driverless. Use the platform to cut your daily operating
                      costs across your fleet.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo3} />
                      </span>
                      <span className="section-subtitle">
                        Overcome Driver Shortages
                      </span>
                    </h2>
                    <p>
                      Driver shortages are being felt across industries around
                      the world. Flux Al operates vehicles operate without
                      needing human help.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo4} />
                      </span>
                      <span className="section-subtitle">Increased Safety</span>
                    </h2>
                    <p>
                      360° sensors give the Flux system input for precise
                      decision making. Take manual control at any time with the
                      push of a button.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo5} />
                      </span>
                      <span className="section-subtitle">
                        Lower Operating Risks
                      </span>
                    </h2>
                    <p>
                      Remove human operators from high risk environments like
                      warehouses or mines while simultaneously improving
                      productivity and safety.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo6} />
                      </span>
                      <span className="section-subtitle">
                        Infinite Scalability
                      </span>
                    </h2>
                    <p>
                      Flux systems scale as quickly as you need, deploy new
                      locations in days. Eliminate time taken for hiring,
                      training and certifying new operators.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-simple-but-mighty" id="simple-but-mighty">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="section-title heading-font">
                <span className="text-primary">Simple </span>
                <span>but Mighty</span>
              </h1>
            </div>
            <div
              className="col-12 col-lg-10 offset-0 offset-lg-1 text-center"
              style={{ marginTop: "32px" }}
            >
              <img src={require("../../assets/images/Product Grouping.png")} />
            </div>
          </div>
        </div>
      </section>

      <section className="section-why-us" id="why-us">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">Why Flux AI?</h1>
              <h2 className="section-subtitle" style={{}}>
                Use the Flux Al platform to make your existing assets and
                infrastructure work harder <br className="d-none d-lg-block" />
                and more efficiently, while also optimizing costs, and
                prioritizing safety
              </h2>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo1_2} />
                      </span>
                      <span className="section-subtitle">Aftermarket Kit</span>
                    </h2>
                    <p>
                      Keep your existing fleet of vehicles, and still add
                      autonomous capabilities to them with the Flux bolt-on
                      aftermarket kit.
                    </p>
                  </div>
                </div>
                <div className="col-0 col-lg-2"></div>
                <div className="col-12 col-lg-5">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo2_2} />
                      </span>
                      <span className="section-subtitle">
                        No Infrastructure Changes
                      </span>
                    </h2>
                    <p>
                      Make no changes to your location - no tapes, stickers, or
                      beacons needed to help the Flux system navigate or
                      operate.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo3_2} />
                      </span>
                      <span className="section-subtitle">
                        Automate All Operations
                      </span>
                    </h2>
                    <p>
                      Unlike other robotics solutions, use one Flux enabled
                      autonomous vehicle for all your operations, exactly like
                      how humans operate.
                    </p>
                  </div>
                </div>
                <div className="col-0 col-lg-2"></div>
                <div className="col-12 col-lg-5">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyLogo4_2} />
                      </span>
                      <span className="section-subtitle">Hive Mind</span>
                    </h2>
                    <p>
                      Automating warehouse, farming, mining and other
                      operations, Flux Al learns from all use-cases and
                      applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-drivn" id="drivn">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title">
                Drivn: our lineup of autonomous forklift tech
              </h1>
              <h2 className="section-subtitle">
                Learn more about Drivn and how our robotic forklifts{" "}
                <br className="d-none d-lg-block" />
                can help your business move goods quicker, safer and more
                reliably
              </h2>
            </div>
            <div className="offset-1 col-10 offset-md-3 col-md-6 text-center">
              <img
                src={require("../../assets/images/drivn.svg").default}
                style={{ width: "100%", margin: "32px 0px" }}
              />
            </div>
            <div className="col-12 text-center">
              <Button
                size="large"
                variant="contained"
                href="http://www.drivnhq.com/"
                target="_blank"
                style={{ marginTop: "16px" }}
              >
                Learn More
              </Button>
            </div>
            <section
              className="section-how-it-works"
              style={{ paddingBottom: "0px" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1 className="section-title">
                      India's first truly autonomous tractor
                    </h1>
                    <h2 className="section-subtitle">
                      See our autonomous tractor prototype in action. Fully
                      autonomous with implement control, safety sensors for
                      obstacle detection, skip passing, headland turns and more.
                    </h2>
                  </div>
                  <div className="col-12 text-center">
                    <div className="youtube-container">
                      <YouTube videoId="hwniVUJGPEs" opts={youtubeOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="col-12 text-center" style={{ marginTop: "32px" }}>
              <Button size="large" variant="contained">
                Coming Soon
              </Button>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section-technology" id="technology">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12"></div>
            <div className="col-12 col-lg-6">
              <div className="section-technology-content">
                <h1 className="section-title">Technology</h1>
                <h2 className="section-subtitle"></h2>
                Our platform has been built to operate in some of the most
                chaotic environments in the world.
                <br />
                <br />
                The hardware and software technologies have been engineered from
                the ground up to support our goal of democratizing autonomous
                trucking.
                <br />
                <br />
                <Link to="/technology">
                  <Button size="large" variant="contained">
                    Know More
                  </Button>
                </Link>
              </div>
              <div className="mobile-spacer"></div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="section-technology-image">
                <video autoPlay loop muted playsInline>
                  <source
                    src={require("../../assets/videos/WebTracking.mp4")}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section-safety" id="safety">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12"></div>
            <div className="col-12 col-lg-6">
              <div className="section-safety-image">
                <img src={require("../../assets/images/safety.jpg")} />
              </div>
              <div className="mobile-spacer"></div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="section-safety-content">
                <h1 className="section-title">Safety</h1>
                <h2 className="section-subtitle"></h2>
                Safety is at the center of everything we do, ensuring we build a
                platform that can be deployed in real world scenarios.
                <br />
                <br />
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    navigate("technology");
                    setTimeout(() => {
                      document
                        .getElementById("technology-safety")
                        ?.scrollIntoView({
                          behavior: "auto",
                        });
                    }, 100);
                  }}
                >
                  Know More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section-how-it-works" id="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="section-title">
                Watch our 2018 Prototype In Action
              </h1>
              <h2 className="section-subtitle">
                We were the first company to rigorously test autonomous vehicles
                on Indian highways, some of the most chaotic and unpredictable
                roadways in the world.
                <i style={{ display: "block", marginTop: "16px" }}>
                  Not a test track, not a controlled environment!
                </i>
              </h2>
            </div>
            <div className="col-12 text-center">
              <div className="youtube-container">
                <YouTube videoId="gvvuXO6BG98" opts={youtubeOptions} />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="section-slider" id="in-action">
        <ProgressSliderComponent
          data={[
            {
              videoSrc: require("../../assets/videos/slider_in_action1.mp4"),
              title: "Pallet Hauling",
              description:
                "Automate pallet hauling operations without needing any human help",
            },
            {
              videoSrc: require("../../assets/videos/slider_in_action2.mp4"),
              title: "Trailer Unloading",
              description:
                "Unload trailers autonomously, drop anywhere in the warehouse",
            },
            {
              videoSrc: require("../../assets/videos/slider_in_action3.mp4"),
              title: "Order Picking",
              description:
                "Automated pallet jacks for driverless order picking operations",
            },
            {
              videoSrc: require("../../assets/videos/slider_in_action4.mp4"),
              title: "Farming",
              description:
                "Flux's solution automates tractors along with implement control",
            },
          ]}
          overlay={null}
        ></ProgressSliderComponent>

        {/* <div className="row">
          <div className="col-12 text-center">
            <Button
              className="button-order-now"
              size="large"
              variant="contained"
              onClick={() => {}}
            >
              Order Now
            </Button>
          </div>
        </div> */}
      </section>

      <section className="section-operations" id="operations">
        <Slider {...operationsSliderSettings}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h1 className="heading-font">
                  Autonomous
                  <br />
                  <span style={{ color: "#574AE2" }}>Farming</span>
                  <br />
                  Operations
                </h1>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <img src={require("../../assets/images/op_farming.png")} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h1 className="heading-font">
                  Autonomous
                  <br />
                  <span style={{ color: "#e07959" }}>Warehouse</span>
                  <br />
                  Operations
                </h1>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <img src={require("../../assets/images/op_warehouse.png")} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h1 className="heading-font">
                  Autonomous
                  <br />
                  <span style={{ color: "#658a54" }}>Mining</span>
                  <br />
                  Operations
                </h1>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <img src={require("../../assets/images/op_mining.png")} />
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </div>
  );
}

export default HomeComponent;
