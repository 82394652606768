import "./technology.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faFlagCheckered,
  faEye,
  faParachuteBox,
  faChartArea,
  faTabletAlt,
  faTrophy,
  faBook,
  faMicrochip,
  faCogs,
  faMapMarkerAlt,
  faRoute,
  faCode,
  faCheckSquare,
  faToggleOn,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Paper, Tabs, Tab } from "@mui/material";
import { useState, useLayoutEffect } from "react";
import YouTube from "react-youtube";

function TechnologyComponent() {
  const [value, setValue] = useState(0);

  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
    },
  };

  const useWindowSize = () => {
    const [deviceType, setDeviceType] = useState("lg");
    useLayoutEffect(() => {
      function updateSize() {
        const width = window.innerWidth;
        if (width <= 575) {
          setDeviceType("xs");
        } else if (width >= 576 && width <= 767) {
          setDeviceType("sm");
        } else if (width >= 768 && width <= 991) {
          setDeviceType("md");
        } else if (width >= 992 && width <= 1199) {
          setDeviceType("lg");
        } else if (width >= 1200) {
          setDeviceType("xl");
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return deviceType;
  };

  const deviceType = useWindowSize();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        <div>{children}</div>
      </div>
    );
  };

  return (
    <div className="technology-component">
      <section className="section-main">
        <video autoPlay loop muted playsInline>
          <source
            src={require("../../assets/videos/WebTracking.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="mask"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-main-content">
                <h1 className="heading-font">
                  <span className="text-white1">Under the</span>
                  <span className="d-none d-lg-inline-block">&nbsp;</span>
                  <span className="text-primary">Hood</span>
                </h1>
                <h2 className="text-white1">
                  purpose-built technology solving complex problems
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-technology">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">
                The Engineering Challenge of a Lifetime
              </h1>
              <h2 className="section-subtitle">
                Across hardware and software platforms, our engineers are
                working to solve new and unique challenges every day.
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Tabs
                value={value}
                onChange={handleChange}
                variant={
                  deviceType === "xs" ||
                  deviceType === "sm" ||
                  deviceType === "md"
                    ? "scrollable"
                    : "fullWidth"
                }
                scrollButtons="auto"
              >
                <Tab
                  icon={<FontAwesomeIcon icon={faMicrochip} />}
                  label="Embedded Systems"
                />
                <Tab icon={<FontAwesomeIcon icon={faCogs} />} label="Sensors" />
                <Tab
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="Perception"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  label="Localization"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faSlidersH} />}
                  label="Controls"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faRoute} />}
                  label="Path Planning"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faCode} />}
                  label="Simulation"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faCheckSquare} />}
                  label="Test Ops"
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img
                        src={require("../../assets/images/embeddedsystems.png")}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faMicrochip} />
                    </div>
                    <div className="technology-subheading">
                      Embedded Systems
                    </div> */}

                    <p>
                      Purpose-built embedded systems ensure the highest
                      performance and full customization based on operational
                      requirements.
                      <br />
                      <br />
                      Another critical task is to interface our state of the art
                      sensor and hardware array with the autonomous mobility
                      software platform in real-time.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/tech2.jpg")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faCogs} />
                    </div>
                    <div className="technology-subheading">Sensors</div> */}

                    <p>
                      We use state of the art sensors inside, and around the
                      vehicle to understand the operation it is performing and
                      the operating environment in real-time.
                      <br />
                      <br />
                      Cameras, LiDAR and 2D safety sensors mounted around the
                      vehicle give us a 360 degree view of the environment
                      ensuring zero blindspots. Operating decisions are made
                      based on this sensor data.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <video autoPlay loop muted playsInline>
                        <source
                          src={require("../../assets/videos/perception.mp4")}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div className="technology-subheading">Perception</div> */}

                    <p>
                      Using our perception stack we understand the surroundings,
                      and intent of objects in the scene around us.
                      <br />
                      <br />
                      These features are accomplished through the use of Object
                      Detection, Semantic Segmentation, Key Point Detection,
                      Feature Extraction, Object Tracking, SLAM, and more.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <video autoPlay loop muted playsInline>
                        <source
                          src={require("../../assets/videos/localization.mp4")}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </div>
                    <div className="technology-subheading">Localization</div> */}

                    <p>
                      Localization is the problem of state estimation by which a
                      vehicle determines where exactly it is located in the
                      environment using the generated map, along with
                      positioning sensors on the vehicle.
                      <br />
                      <br />
                      Other modules of the self-driving technology stack make
                      decisions off of this localization information.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/controls.gif")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </div>
                    <div className="technology-subheading">Controls</div> */}

                    <p>
                      Precisely controlling the vehicle is critical to smooth
                      operations. With advanced algorithms and cutting-edge
                      sensors, the software enables precise control, enhanced
                      safety, and reliable performance.
                      <br />
                      <br />
                      Continuously evolving and driven by innovation, control's
                      stack sets new benchmarks in autonomous mobility. It
                      empowers the systems to navigate complex scenarios, adapt
                      to changing environments, and seamlessly operate various
                      vehicle and robotic/vehicle attachments.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/tech5.jpg")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faRoute} />
                    </div>
                    <div className="technology-subheading">Path Planning</div> */}

                    <p>
                      Path planning is a computational problem to find a
                      sequence of valid configurations that moves the object
                      from the source to destination.
                      <br />
                      <br />
                      This involves predicting future states of dynamic
                      obstacles, decision making, trajectory generation and
                      velocity control.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img
                        src={require("../../assets/images/simulation.png")}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faCode} />
                    </div>
                    <div className="technology-subheading">Simulation</div> */}

                    <p>
                      Simulations allow us to test our technology platform
                      against several hundred thousand scenarios virtually, and
                      with no risk.
                      <br />
                      <br />
                      Once the modules are tested in the simulator, we begin
                      physical testing. In most cases, these modules tested
                      virtually can be transferred onto the vehicle without
                      significant modifications.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/testing.png")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    {/* <div className="icon-container">
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </div>
                    <div className="technology-subheading">Testing</div> */}

                    <p>
                      We use a 7-step testing process to ensure every module we
                      build is able to operate safely and efficiently when we
                      deploy it onto a vehicle.
                      <br />
                      <br />
                      This rigorous approach includes testing our vehicles
                      virtually in simulators, modularly, and finally in the
                      real world once we validate the modules.
                    </p>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </section>

      <section className="section-safety" id="technology-safety">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">
                Our Approach to Safety
              </h1>
              <h2 className="section-subtitle">
                The key aspects of our Safety Approach include:
              </h2>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faShieldAlt} />
                {/* <Divider /> */}
                <div>System Safety</div>
              </div>
            </div>
            {/* <div className="col-6 col-lg-3">
              <Paper className="safety-card">
                <FontAwesomeIcon icon={faFlagCheckered} />
                <Divider />
                <div>Operational Design Domain</div>
              </Paper>
            </div>
            <div className="col-6 col-lg-3">
              <Paper className="safety-card">
                <FontAwesomeIcon icon={faEye} />
                <Divider />
                <div>Object and Event Detection and Response</div>
              </Paper>
            </div> */}
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faToggleOn} />
                {/* <Divider /> */}
                <div>Physical Safety</div>
              </div>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faEye} />
                {/* <Divider /> */}
                <div>Operational Safety</div>
              </div>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faParachuteBox} />
                {/* <Divider /> */}
                <div>Fallback</div>
              </div>
            </div>
            {/* <div className="col-6 col-lg-3">
              <Paper className="safety-card">
                <FontAwesomeIcon icon={faChartArea} />
                <Divider />
                <div>Validation</div>
              </Paper>
            </div> */}
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faTabletAlt} />
                {/* <Divider /> */}
                <div>Human-Machine Interface</div>
              </div>
            </div>
            {/* <div className="col-6 col-lg-3">
              <Paper className="safety-card">
                <FontAwesomeIcon icon={faTrophy} />
                <Divider />
                <div>Roadworthiness</div>
              </Paper>
            </div> */}
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faBook} />
                {/* <Divider /> */}
                <div>Education and Training</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-prototype">
        <div className="container">
          <div className="row prototype-video-container">
            <div className="col-12"></div>
            <div className="col-12 col-lg-5">
              <div className="youtube-container">
                <YouTube videoId="gvvuXO6BG98" opts={youtubeOptions} />
              </div>
              
            </div>
            <div className="col-12 col-lg-7">
              <div className="section-safety-content">
                <h1 className="section-title">Watch our Prototype In Action</h1>
                <h2 className="section-subtitle"></h2>
                Autonomous driving on Indian highways, some of the most chaotic
                and unpredictable roadways in the world.
                <br />
                <br />
                More exciting footage coming soon!
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default TechnologyComponent;
