import { useState, useEffect, useLayoutEffect } from "react";
import "./media.component.scss";
import { Routes, Route, Link, NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider, TextField } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

function MediaSectionComponent() {
  return (
    <section className="section-media" id="media">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="section-title heading-font">Media</h1>
            {/* <h2 className="section-subtitle"></h2> */}
          </div>
          <div className="col-12 col-lg-6">
            <a
              href="https://www.forbesindia.com/article/9th-anniversary-special/nine-startups-with-promise/50217/1"
              className="media-card"
            >
              <img src={require("../../assets/images/news-forbes2.png")} />
              <h2 className="section-subtitle">Forbes</h2>
              <p>
                "Flux Auto is gearing up to change the way trucks are driven on
                messy and chaotic roads. The technology can be retrofitted onto
                in-use trucks."
              </p>
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <a
              href="https://medium.com/udacity/experience-self-driving-trucks-in-india-with-flux-auto-b1faaeba0089"
              className="media-card"
            >
              <img src={require("../../assets/images/news-udacity2.png")} />
              <h2 className="section-subtitle">Udacity</h2>
              <p>
                "Building self-driving trucks in India is no small feat. It is
                even more challenging than other parts of the world due to the
                complexity and infrastructure."
              </p>
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <a
              href="https://techcrunch.com/2017/08/10/flux-auto/"
              className="media-card"
            >
              <img src={require("../../assets/images/news-techcrunch2.png")} />
              <h2 className="section-subtitle">Tech Crunch</h2>
              <p>
                "Flux Auto is developing technology that could enable any kind
                of truck to be retrofitted with self-driving technology at a far
                lower price."
              </p>
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <a
              href="https://economictimes.indiatimes.com/small-biz/startups/features/beating-google-tesla-this-startups-self-driving-truck-is-all-set-to-hit-indian-roads-flux-a/articleshow/62333560.cms"
              className="media-card"
            >
              <img
                src={require("../../assets/images/news-economictimes2.png")}
              />
              <h2 className="section-subtitle">Economic Times</h2>
              <p>
                "Flux Auto believes the chaotic, poorly-kept roads of India are
                ideal grounds for them to master their self-driving technology."
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MediaSectionComponent;
