import { useLayoutEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./app.component.scss";

import HomeComponent from "../screens/home/home.component";
import TechnologyComponent from "../screens/technology/technology.component";
import CareersComponent from "../screens/careers/careers.component";
import PrivacyComponent from "../screens/privacy/privacy.component";
import TermsComponent from "../screens/terms/terms.component";

import HeaderComponent from "../components/header/header.component";
import FooterComponent from "../components/footer/footer.component";
import MediaSectionComponent from "../components/media/media.component";
import InvestorsSectionComponent from "../components/investors/investors.component";
import ContactSectionComponent from "../components/contact/contact.component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e64653",
    },
    secondary: {
      main: "#9ca3af",
    },
  },
  shape: {
    borderRadius: 3,
  },
});

function AppComponent() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className="app-component">
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/technology" element={<TechnologyComponent />} />
          <Route path="/careers" element={<CareersComponent />} />
          <Route path="/privacy" element={<PrivacyComponent />} />
          <Route path="/terms" element={<TermsComponent />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {/* {!pathname.toLowerCase().startsWith("/careers") && (
          <CareersSectionComponent />
        )} */}
        {!pathname.toLowerCase().startsWith("/privacy") &&
          !pathname.toLowerCase().startsWith("/terms") && (
            <MediaSectionComponent />
          )}
        {!pathname.toLowerCase().startsWith("/privacy") &&
          !pathname.toLowerCase().startsWith("/terms") && (
            <InvestorsSectionComponent />
          )}
        {!pathname.toLowerCase().startsWith("/careers") &&
          !pathname.toLowerCase().startsWith("/privacy") &&
          !pathname.toLowerCase().startsWith("/terms") && (
            <ContactSectionComponent />
          )}
        <FooterComponent pathname={pathname.toLowerCase()} />
      </div>
    </ThemeProvider>
  );
}

export default AppComponent;
