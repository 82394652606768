import "./privacy.component.scss";
import { useState, useLayoutEffect } from "react";

function PrivacyComponent() {
  const useWindowSize = () => {
    const [deviceType, setDeviceType] = useState("lg");
    useLayoutEffect(() => {
      function updateSize() {
        const width = window.innerWidth;
        if (width <= 575) {
          setDeviceType("xs");
        } else if (width >= 576 && width <= 767) {
          setDeviceType("sm");
        } else if (width >= 768 && width <= 991) {
          setDeviceType("md");
        } else if (width >= 992 && width <= 1199) {
          setDeviceType("lg");
        } else if (width >= 1200) {
          setDeviceType("xl");
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return deviceType;
  };

  const deviceType = useWindowSize();

  return (
    <div className="privacy-component">
      <section className="section-main">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">Privacy Policy</h1>
              <h2 className="section-subtitle">Effective date: 08/30/2024</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="content c14">
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-0 start" start="1">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Introduction</span>
                  </li>
                </ol>
                <p className="c9 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">Welcome to&nbsp;</span>
                  <span className="c6">Flux Auto, Inc.</span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Flux Auto, Inc.&nbsp;(“us”, “we”, or “our”)
                    operates&nbsp;https://fluxauto.xyz&nbsp;(hereinafter
                    referred to as “
                  </span>
                  <span className="c6">Service</span>
                  <span className="c2">”).</span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Our Privacy Policy governs your visit
                    to&nbsp;https://fluxauto.xyz, and explains how we collect,
                    safeguard and disclose information that results from your
                    use of our Service.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We use your data to provide and improve Service. By using
                    Service, you agree to the collection and use of information
                    in accordance with this policy. Unless otherwise defined in
                    this Privacy Policy, the terms used in this Privacy Policy
                    have the same meanings as in our Terms and Conditions.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">Our Terms and Conditions (“</span>
                  <span className="c6">Terms</span>
                  <span className="c2">
                    ”) govern all use of our Service and together with the
                    Privacy Policy constitutes your agreement with us (“
                  </span>
                  <span className="c6">agreement</span>
                  <span className="c2">”).</span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-0" start="2">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Definitions</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">SERVICE&nbsp;</span>
                  <span className="c2">
                    means the&nbsp;https://fluxauto.xyz website&nbsp;operated
                    by&nbsp;Flux Auto, Inc.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">PERSONAL DATA</span>
                  <span className="c2">
                    &nbsp;means data about a living individual who can be
                    identified from those data (or from those and other
                    information either in our possession or likely to come into
                    our possession).
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">USAGE DATA</span>
                  <span className="c2">
                    &nbsp;is data collected automatically either generated by
                    the use of Service or from Service infrastructure itself
                    (for example, the duration of a page visit).
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">COOKIES</span>
                  <span className="c2">
                    &nbsp;are small files stored on your device (computer or
                    mobile device).
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">DATA CONTROLLER</span>
                  <span className="c2">
                    &nbsp;means a natural or legal person who (either alone or
                    jointly or in common with other persons) determines the
                    purposes for which and the manner in which any personal data
                    are, or are to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your data.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">
                    DATA PROCESSORS (OR SERVICE PROVIDERS)
                  </span>
                  <span className="c2">
                    &nbsp;means any natural or legal person who processes the
                    data on behalf of the Data Controller. We may use the
                    services of various Service Providers in order to process
                    your data more effectively.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">DATA SUBJECT&nbsp;</span>
                  <span className="c2">
                    is any living individual who is the subject of Personal
                    Data.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c6">THE USER&nbsp;</span>
                  <span className="c2">
                    is the individual using our Service. The User corresponds to
                    the Data Subject, who is the subject of Personal Data.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-0" start="3">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Information Collection and Use</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-0" start="4">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Types of Data Collected</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c6">Personal Data</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (“
                  </span>
                  <span className="c6">Personal Data</span>
                  <span className="c2">
                    ”). Personally identifiable information may include, but is
                    not limited to:
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_4-1 start" start="1">
                  <li className="c3 li-bullet-1">
                    <span className="c2">Email address</span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_4-1" start="2">
                  <li className="c3 li-bullet-1">
                    <span className="c2">First name and last name</span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_4-1" start="3">
                  <li className="c3 li-bullet-1">
                    <span className="c2">Phone number</span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from
                    us&nbsp;by following the unsubscribe link.
                  </span>
                </p>
                <p className="c9 c15">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c6">Usage Data</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We may also collect information that your browser sends
                    whenever you visit our Service or when you access Service by
                    or through a mobile device (“
                  </span>
                  <span className="c6">Usage Data</span>
                  <span className="c2">”).</span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    This Usage Data may include information such as your
                    computer's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    you visit, the time and date of your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    When you access Service with a mobile device, this Usage
                    Data may include information such as the type of mobile
                    device you use, your mobile device unique ID, the IP address
                    of your mobile device, your mobile operating system, the
                    type of mobile Internet browser you use, unique device
                    identifiers and other diagnostic data.
                  </span>
                </p>
                <p className="c9 c15">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c6">Tracking Cookies Data</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We use cookies and similar tracking technologies to track
                    the activity on our Service and we hold certain information.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">Examples of Cookies we use:</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-1 start" start="1">
                  <li className="c3 li-bullet-1">
                    <span className="c6">Session Cookies:</span>
                    <span className="c2">
                      &nbsp;We use Session Cookies to operate our Service.
                    </span>
                  </li>
                </ol>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-1" start="2">
                  <li className="c3 li-bullet-1">
                    <span className="c6">Preference Cookies:</span>
                    <span className="c2">
                      &nbsp;We use Preference Cookies to remember your
                      preferences and various settings.
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-1" start="3">
                  <li className="c3 li-bullet-1">
                    <span className="c6">Security Cookies:</span>
                    <span className="c2">
                      &nbsp;We use Security Cookies for security purposes.
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-1" start="4">
                  <li className="c3 li-bullet-1">
                    <span className="c6">Advertising Cookies:</span>
                    <span className="c2">
                      &nbsp;Advertising Cookies are used to serve you with
                      advertisements that may be relevant to you and your
                      interests.
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c6">Other Data</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    While using our Service, we may also collect the following
                    information: sex, age, date of birth, place of birth,
                    passport details, citizenship, registration at place of
                    residence and actual address, telephone number (work,
                    mobile), details of documents on education, qualification,
                    professional training, employment agreements, non-disclosure
                    agreements, information on bonuses and compensation,
                    information on marital status, family members, social
                    security (or other taxpayer identification) number, office
                    location and other data.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_2-0" start="5">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Use of Data</span>
                  </li>
                </ol>
                <p className="c9 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    Flux Auto, Inc.&nbsp;uses the collected data for various
                    purposes:
                  </span>
                </p>
                <p className="c9 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1 start" start="1">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to provide and maintain our Service;
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="2">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to notify you about changes to our Service;
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="3">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      to allow you to participate in interactive features of our
                      Service when you choose to do so;
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="4">
                  <li className="c3 li-bullet-1">
                    <span className="c2">to provide customer support; </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="5">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      to gather analysis or valuable information so that we can
                      improve our Service;
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="6">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to monitor the usage of our Service;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="7">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to detect, prevent and address technical issues;
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="8">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to fulfill any other purpose for which you provide it;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="9">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="10">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="11">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="12">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      in any other way we may describe when you provide the
                      information;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_6-1" start="13">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      for any other purpose with your consent.
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_7-0 start" start="6">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Retention of Data</span>
                  </li>
                </ol>
                <p className="c9 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We will retain your Personal Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy.
                    We will retain and use your Personal Data to the extent
                    necessary to comply with our legal obligations (for example,
                    if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal
                    agreements and policies.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time
                    periods.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_7-0" start="7">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Transfer of Data</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Your information, including Personal Data, may be
                    transferred to – and maintained on – computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ from those of your jurisdiction.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    If you are located outside United States and choose to
                    provide information to us, please note that we transfer the
                    data, including Personal Data, to United States and process
                    it there.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c27">
                  <span className="c2">
                    Flux Auto, Inc.&nbsp;will take all the steps reasonably
                    necessary to ensure that your data is treated securely and
                    in accordance with this Privacy Policy and no transfer of
                    your Personal Data will take place to an organisation or a
                    country unless there are adequate controls in place
                    including the security of your data and other personal
                    information.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_7-0" start="8">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Disclosure of Data</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    We may disclose personal information that we collect, or you
                    provide:
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_9-1 start" start="1">
                  <li className="c3 c7 li-bullet-1">
                    <span className="c6">Disclosure for Law Enforcement.</span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">
                    Under certain circumstances, we may be required to disclose
                    your Personal Data if required to do so by law or in
                    response to valid requests by public authorities.
                  </span>
                </p>
                <p className="c1 c11">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_9-1" start="2">
                  <li className="c3 c7 li-bullet-2">
                    <span className="c6">Business Transaction.</span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">
                    If we or our subsidiaries are involved in a merger,
                    acquisition or asset sale, your Personal Data may be
                    transferred.
                  </span>
                </p>
                <p className="c1 c18">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-1 start" start="1">
                  <li className="c9 c7 c25 li-bullet-3">
                    <span className="c6">
                      Other cases. We may disclose your information also:
                    </span>
                  </li>
                </ol>
                <p className="c9 c7 c24">
                  <span className="c6">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2 start" start="1">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      to our subsidiaries and affiliates;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2" start="2">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      to contractors, service providers, and other third parties
                      we use to support our business;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2" start="3">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      to fulfill the purpose for which you provide it;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2" start="4">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      for the purpose of including your company’s logo on our
                      website;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2" start="5">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      for any other purpose disclosed by us when you provide the
                      information;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_10-2" start="6">
                  <li className="c9 c21 c22 li-bullet-4">
                    <span className="c2">
                      with your consent in any other cases;
                    </span>
                  </li>
                </ol>
                <p className="c9 c21">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_11-0 start" start="9">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Security of Data</span>
                  </li>
                </ol>
                <p className="c7 c9">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    The security of your data is important to us but remember
                    that no method of transmission over the Internet or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_11-0" start="10">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">
                      Your Data Protection Rights Under General Data Protection
                      Regulation (GDPR)
                    </span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    If you are a resident of the European Union (EU) and
                    European Economic Area (EEA), you have certain data
                    protection rights, covered by GDPR. – See more at
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We aim to take reasonable steps to allow you to correct,
                    amend, delete, or limit the use of your Personal Data.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    If you wish to be informed what Personal Data we hold about
                    you and if you want it to be removed from our systems,
                    please email us at contact@fluxauto.xyz.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    In certain circumstances, you have the following data
                    protection rights:
                  </span>
                </p>
                <p className="c0 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1 start" start="1">
                  <li className="c3 li-bullet-2">
                    <span className="c2">
                      the right to access, update or to delete the information
                      we have on you;
                    </span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1" start="2">
                  <li className="c3 li-bullet-5">
                    <span className="c2">
                      the right of rectification. You have the right to have
                      your information rectified if that information is
                      inaccurate or incomplete;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1" start="3">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      the right to object. You have the right to object to our
                      processing of your Personal Data;
                    </span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1" start="4">
                  <li className="c3 li-bullet-6">
                    <span className="c2">
                      the right of restriction. You have the right to request
                      that we restrict the processing of your personal
                      information;
                    </span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1" start="5">
                  <li className="c3 li-bullet-5">
                    <span className="c2">
                      the right to data portability. You have the right to be
                      provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_13-1" start="6">
                  <li className="c3 li-bullet-5">
                    <span className="c2">
                      the right to withdraw consent.&nbsp;You also have the
                      right to withdraw your consent at any time where we rely
                      on your consent to process your personal information;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Please note that we may ask you to verify your identity
                    before responding to such requests. Please note, we may not
                    able to provide Service without some necessary data.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    You have the right to complain to a Data Protection
                    Authority about our collection and use of your Personal
                    Data. For more information, please contact your local data
                    protection authority in the European Economic Area (EEA).
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_14-0 start" start="11">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">
                      Your Data Protection Rights under the California Privacy
                      Protection Act (CalOPPA)
                    </span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    CalOPPA is the first state law in the nation to require
                    commercial websites and online services to post a privacy
                    policy. The law’s reach stretches well beyond California to
                    require a person or company in the United States (and
                    conceivable the world) that operates websites collecting
                    personally identifiable information from California
                    consumers to post a conspicuous privacy policy on its
                    website stating exactly the information being collected and
                    those individuals with whom it is being shared, and to
                    comply with this policy. – See more at:
                    https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                  </span>
                </p>
                <p className="c0">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    According to CalOPPA we agree to the following:
                  </span>
                </p>
                <p className="c9 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_16-1 start" start="1">
                  <li className="c3 li-bullet-1">
                    <span className="c2">
                      users can visit our site anonymously;
                    </span>
                  </li>
                </ol>
                <p className="c9 c12">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_16-1" start="2">
                  <li className="c3 li-bullet-6">
                    <span className="c2">
                      our Privacy Policy link includes the word “Privacy”, and
                      can easily be found on the page specified above on the
                      home page of our website;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_16-1" start="3">
                  <li className="c3 li-bullet-5">
                    <span className="c2">
                      users will be notified of any privacy policy changes on
                      our Privacy Policy Page;
                    </span>
                  </li>
                </ol>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_16-1" start="4">
                  <li className="c3 li-bullet-5">
                    <span className="c2">
                      users are able to change their personal information by
                      emailing us at contact@fluxauto.xyz.
                    </span>
                  </li>
                </ol>
                <p className="c9 c10">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    Our Policy on “Do Not Track” Signals:
                  </span>
                </p>
                <p className="c9 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    We honor Do Not Track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track browser
                    mechanism is in place. Do Not Track is a preference you can
                    set in your web browser to inform websites that you do not
                    want to be tracked.
                  </span>
                </p>
                <p className="c9 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    You can enable or disable Do Not Track by visiting the
                    Preferences or Settings page of your web browser.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_17-0 start" start="12">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Service Providers</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We may employ third party companies and individuals to
                    facilitate our Service (“
                  </span>
                  <span className="c6">Service Providers</span>
                  <span className="c2">
                    ”), provide Service on our behalf, perform Service-related
                    services or assist us in analysing how our Service is used.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c1 c17">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_17-0" start="13">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Links to Other Sites</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    Our Service may contain links to other sites that are not
                    operated by us. If you click a third party link, you will be
                    directed to that third party's site. We strongly advise you
                    to review the Privacy Policy of every site you visit.
                  </span>
                </p>
                <p className="c9">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </span>
                </p>
                <p className="c1 c17">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_17-0" start="14">
                  <li className="c7 c8 li-bullet-0">
                    <span className="c5">Children's Privacy</span>
                  </li>
                </ol>
                <p className="c9 c10 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c9 c7 c10">
                  <span className="c2">
                    Our Services are not intended for use by children under the
                    age of 13 (“
                  </span>
                  <span className="c6">Children</span>
                  <span className="c2">”). </span>
                </p>
                <p className="c9 c10 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c9 c10 c7">
                  <span className="c2">
                    We do not knowingly collect personally identifiable
                    information from Children under 13. If you become aware that
                    a Child has provided us with Personal Data, please contact
                    us. If we become aware that we have collected Personal Data
                    from Children without verification of parental consent, we
                    take steps to remove that information from our servers.
                  </span>
                </p>
                <p className="c0 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_17-0" start="15">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Changes to This Privacy Policy</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </span>
                </p>
                <p className="c9">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    We will let you know via email and/or a prominent notice on
                    our Service, prior to the change becoming effective and
                    update “effective date” at the top of this Privacy Policy.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c0">
                  <span className="c2">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </span>
                </p>
                <p className="c1">
                  <span className="c2">&nbsp;</span>
                </p>
                <ol className="c4 lst-kix_list_17-0" start="16">
                  <li className="c8 c7 li-bullet-0">
                    <span className="c5">Contact Us</span>
                  </li>
                </ol>
                <p className="c0 c7">
                  <span className="">&nbsp;</span>
                </p>
                <p className="c0 c7">
                  <span className="c2">
                    If you have any questions about this Privacy Policy, please
                    contact us:
                  </span>
                </p>
                <p className="c0 c7">
                  <span className="c2">&nbsp;</span>
                </p>
                <p className="c1 c17">
                  <span className="c2">By email: contact@fluxauto.xyz.</span>
                </p>
                <p className="c1 c17">
                  <span className="c2">&nbsp;</span>
                </p>
                <div>
                  <p className="c7 c19">
                    <span className="c13"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyComponent;
