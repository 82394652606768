import { useState, useEffect, useLayoutEffect } from "react";
import "./contact.component.scss";
import { Routes, Route, Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Drawer,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Opacity } from "@mui/icons-material";

function ContactSectionComponent() {
  const [subject, setSubject] = useState(null);

  const handleChange = (event) => {
    setSubject(event.target.value);
  };

  return (
    <form
      acceptCharset="utf-8"
      action="https://formspree.io/f/myyaoqbq"
      method="post"
    >
      <section className="contact-component" id="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title heading-font">Contact Us</h1>
              <h2 className="section-subtitle">
                Want to see our tech in action? Or try it out for yourself?
                <br />
                We're always looking for partners. Reach out to us!
              </h2>
            </div>
            <div className="col-12 col-lg-10 offset-0 offset-lg-1 text-center">
              <div className="contact-card">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <InputLabel id="name-label">Full Name</InputLabel>
                    <TextField
                      labelId="name-label"
                      name="name"
                      id="full-name"
                      variant="standard"
                      placeholder="John Doe"
                      sx={{ width: "100%", marginBottom: "16px" }}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <InputLabel id="email-label">Email ID</InputLabel>
                    <TextField
                      labelId="email-label"
                      name="email"
                      id="email-address"
                      type="email"
                      variant="standard"
                      placeholder="example@mail.com"
                      sx={{
                        width: "100%",
                        marginBottom: "16px",
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <InputLabel id="phone-label">Phone Number</InputLabel>
                    <TextField
                      labelId="phone-label"
                      name="telephone"
                      id="telephone"
                      type="tel"
                      variant="standard"
                      placeholder="+1 234 567 8910"
                      sx={{ width: "100%", marginBottom: "16px" }}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <InputLabel id="subject-label">Subject</InputLabel>
                    <NativeSelect
                      labelId="subject-label"
                      defaultValue={""}
                      inputProps={{
                        name: "subject",
                        id: "subject",
                        placeholder: "Choose a Subject",
                      }}
                      label="Subject"
                      sx={{ width: "100%", marginBottom: "16px" }}
                      required
                    >
                      <option disabled value="">
                        Choose a Subject
                      </option>
                      <option value={"Sales"}>Sales</option>
                      <option value={"Partnerships"}>Partnerships</option>
                      <option value={"Hiring"}>Hiring</option>
                      <option value={"Investments"}>Investments</option>
                      <option value={"Other"}>Other</option>
                    </NativeSelect>
                  </div>
                  <div className="col-12">
                    <InputLabel id="message-label">Message</InputLabel>
                    <TextField
                      labelId="message-label"
                      name="message"
                      id="message"
                      variant="standard"
                      placeholder="Send us a message"
                      sx={{ width: "100%", marginBottom: "16px" }}
                      multiline
                      rows={4}
                      required
                    />
                  </div>
                  <div className="col-12 text-center">
                    <Button size="large" type="submit" variant="contained">
                      Send Message
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}

export default ContactSectionComponent;
