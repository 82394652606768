import { useState, useEffect } from "react";
import "./progress-slider.component.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useLayoutEffect } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function ProgressSliderComponent({ data, overlay }) {
  const [state, setState] = useState(0);
  const settings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: true,
    arrows: false,
    dotsClass: "slider-dots",
    fade: true,
  };

  return (
    <div className="progress-slider">
      <div>
        <Slider
          {...settings}
          beforeChange={(index) => {
            setState(index);
          }}
          style={{ position: "relative" }}
        >
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[0].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[1].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[2].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[3].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Slider>
        {overlay && (
          <div className="overlay">
            <CenterFocusWeakIcon
              className="text-black1"
              style={{ fontSize: 28, marginBottom: 0 }}
            />
            <h4>{overlay.title}</h4>
            <p>{overlay.description}</p>
          </div>
        )}
      </div>

      <div className="d-none d-lg-block">
        <div className="row" style={{ margin: 0, marginTop: "16px" }}>
          <div className="col-12 col-lg-3">
            <div className={state === 3 ? "text-black1" : ""}>
              <h4>{data[0].title}</h4>
              <p>{data[0].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className={state === 0 ? "text-black1" : ""}>
              <h4>{data[1].title}</h4>
              <p>{data[1].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className={state === 1 ? "text-black1" : ""}>
              <h4>{data[2].title}</h4>
              <p>{data[2].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className={state === 2 ? "text-black1" : ""}>
              <h4>{data[3].title}</h4>
              <p>{data[3].description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none">
        <div
          className="row text-black1"
          style={{ margin: 0, marginTop: "16px" }}
        >
          {state === 3 && (
            <div className="col-12 col-lg-3">
              <h4>{data[0].title}</h4>
              <p>{data[0].description}</p>
            </div>
          )}

          {state === 0 && (
            <div className="col-12 col-lg-3">
              <h4>{data[1].title}</h4>
              <p>{data[1].description}</p>
            </div>
          )}

          {state === 1 && (
            <div className="col-12 col-lg-3">
              <h4>{data[2].title}</h4>
              <p>{data[2].description}</p>
            </div>
          )}

          {state === 2 && (
            <div className="col-12 col-lg-3">
              <h4>{data[3].title}</h4>
              <p>{data[3].description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgressSliderComponent;
